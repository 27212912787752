import React, { useState, useEffect } from 'react';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import Configuration from '../../Config';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { useNavigate } from "react-router-dom";
import axios from 'axios';

const theme = createTheme({
    palette: {
        primary: {
          main: '#d53e2c',
        },
        secondary: {
          main: '#646464',
        },
    },
});

export default function SignIn() {
    const navigate = useNavigate();

    useEffect(() => {
        let token = localStorage.getItem("token");
        if (token && token.length) navigate("/pt");
    }, []);

    const [emailError, setEmailError] = useState("");
    const [passwordError, setPasswordError] = useState("");
    const handleSubmit = (event) => {
        event.preventDefault();
        const data = new FormData(event.currentTarget);
        setEmailError("");
        setPasswordError("");
        if (data.get('email').length <= 0) {
            setEmailError("Insira o seu email.");
            return;
        } else if(!/^[a-zA-Z0-9]+@(?:[a-zA-Z0-9]+\.)+[A-Za-z]+$/.test(data.get('email'))) {
            setEmailError("Insira um email válido.");
            return;
        }
        if (data.get('password').length <= 0) {
            setPasswordError("Insira a sua password.");
            return;
        }
        axios.post(Configuration.BASE_URL + "/login", {
            email: data.get('email'),
            password: data.get('password'),
        }).then((response) => {
            localStorage.setItem("token", response.data.accessToken);
            navigate("/");
        }).catch((error) => {
            setEmailError("Email ou password incorretos.");
        });
    };

    return (
        <ThemeProvider theme={theme}>
        <Container component="main" maxWidth="xs">
            <CssBaseline />
            <Box
            sx={{
                marginTop: 8,
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
            }}
            >
            <Avatar sx={{ m: 1, bgcolor: 'danger.main' }}>
                <LockOutlinedIcon />
            </Avatar>
            <Typography component="h1" variant="h5">
                Autenticação
            </Typography>
            <Box component="form" onSubmit={handleSubmit} sx={{ mt: 1 }}>
                <TextField
                    margin="normal"
                    fullWidth
                    id="email"
                    label="Endereço de Email"
                    name="email"
                    autoComplete="email"
                    autoFocus
                    error={emailError !== ""}
                    helperText={emailError === "" ? '' : emailError}
                />
                <TextField
                    margin="normal"
                    fullWidth
                    name="password"
                    label="Palavra Passe"
                    type="password"
                    id="password"
                    autoComplete="current-password"
                    error={passwordError !== ""}
                    helperText={passwordError === "" ? '' : passwordError}
                />
                <Button
                    type="submit"
                    fullWidth
                    variant="contained"
                    sx={{ mt: 3, mb: 2 }}
                >
                    Autenticar
                </Button>
            </Box>
            </Box>
        </Container>
        </ThemeProvider>
    );
}