import React, { useState, useRef, useEffect } from 'react';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';

export default function TextForm({ editing = true, handleSubmit, item={}}) {
    const [titleError, setTitleError] = useState("");
    const [textError, setTextError] = useState("");
    
    const [title, setTitle] = useState("")
    const [text, setText] = useState("")

    useEffect(async () => {
        if (item.title) {
            setTitle(item.title);
            setText(item.text);
        }
    }, [item]);

    const handleSubmitForm = (event) => {
        event.preventDefault();
        setTitleError("");
        setTextError("");
        if (title.length <= 0) {
            setTitleError("Insira o titulo.");
            return;
        }
        if (text.length <= 0) {
            setTextError("Insira a descrição.");
            return;
        }
        handleSubmit({ title, text });
    };

    return (
        <Box component="form" onSubmit={handleSubmitForm} sx={{ mt: 1 }}>
            <TextField
                margin="normal"
                fullWidth
                id="title"
                label="Titulo"
                value={title}
                onChange={(e)=> setTitle(e.target.value)}
                name="title"
                autoFocus
                error={titleError != ""}
                helperText={titleError === "" ? '' : titleError}
            />
            <br></br>
            <br></br>
            <TextField
                label="Texto"
                fullWidth
                name="text"
                value={text}
                onChange={(e)=>setText(e.target.value)}
                multiline
                minRows={10}
                error={textError != ""}
                helperText={textError === "" ? '' : textError}
            />
            <Button
                type="submit"
                fullWidth
                variant="contained"
                sx={{ mt: 3, mb: 2 }}>
                {editing ? "Editar" : "Adicionar"}
            </Button>
        </Box>
    );
}
