import React, { useState, useRef } from 'react';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import styled from 'styled-components';
import axios from 'axios';
import Configuration from '../../Config';
import { useParams, useNavigate } from "react-router-dom";
import StoreForm from './StoreForm';
import IconButton from '@mui/material/IconButton';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

const Input = styled('input')({
  display: 'none',
});

export default function AddStore() {
  const navigate = useNavigate();
  const { language } = useParams();

  const handleSubmit = (data) => {
      let token = localStorage.getItem("token");
      axios.post(Configuration.BASE_URL + "/store", data, { headers: { Authorization: 'Bearer ' + token}})
      .then((response) => {
          navigate("/stores/"+language);
      }).catch((error) => {
      });
  };

  return (
    <Grid item xs={12} md={12} lg={12}>
    <Paper
      sx={{
        p: 2,
        display: 'flex',
        flexDirection: 'column',
      }}
    > 
      <Grid item md={12}>
        <IconButton onClick={() => navigate('/stores/' + language) } aria-label="qrcode">
          <ArrowBackIcon />
        </IconButton>
        <Typography variant="h5" color="text.secondary" align="center">
          Adicionar Loja {language == "es" ? "Espanha" : "Portugal"}
        </Typography>
        <StoreForm handleSubmit={handleSubmit} edit={false} language={language}/>
      </Grid>
    </Paper>
  </Grid>
  );
}
