import React, { useState, useEffect } from 'react';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';
import styled from 'styled-components';
import axios from 'axios';
import Configuration from '../../Config';
import { useParams, useNavigate } from "react-router-dom";
import TextForm from './TextForm';
import IconButton from '@mui/material/IconButton';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

export default function EditItemText() {
    const navigate = useNavigate();
    const { id } = useParams();
    const [item, setItem] = useState({});
    useEffect(async () => {
        fetchTextItem(id);
    }, []);
      
    const fetchTextItem = (id) => {
        let token = localStorage.getItem("token");
        axios.get(Configuration.BASE_URL + "/texts/" + id, { headers: { Authorization: 'Bearer ' + token }
        }).then(function (response) {
            setItem(response.data);
        }).catch(function (error) {
            console.log(error);
        });
    } 

    const handleSubmit = (data) => {
        let token = localStorage.getItem("token");
        axios.post(Configuration.BASE_URL + "/texts/edit/" +  id , data, { headers: { Authorization: 'Bearer ' + token} })
            .then((response) => {
                navigate("/texts/"+item.language);
            }).catch((error) => {
        });
    };

    return (
        <Grid item xs={12} md={12} lg={12}>
        <Paper
        sx={{
            p: 2,
            display: 'flex',
            flexDirection: 'column',
        }}
        > 
        <Grid item md={12}>
            <IconButton onClick={() => navigate('/texts/' + item.language) } aria-label="qrcode">
                <ArrowBackIcon />
            </IconButton>
            <Typography variant="h5" color="text.secondary" align="center">
                Editar Texto
            </Typography>
            {item ? <TextForm handleSubmit={handleSubmit} editing={true} item={item} />
            : <Box sx={{ display: 'flex', alignItems: "center", justifyContent: "center" }}><CircularProgress /></Box>
            }
        </Grid>
        </Paper>
    </Grid>
    );
}
