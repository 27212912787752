import React, { useState, useRef, useEffect } from 'react';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import styled from 'styled-components';
import FormHelperText from '@mui/material/FormHelperText';

const Input = styled('input')({
  display: 'none',
});

export default function MenuForm({ editing = false, handleSubmit, item={}}) {
    const [titleError, setTitleError] = useState("");
    const [descriptionError, setDescriptionError] = useState("");
    const [sizesError, setSizesError] = useState("");
    const [imageError, setImageError] = useState("")
    const [imgSrc, setImgSrc] = useState("");
    const fileInputRef = useRef(null);
    
    const [title, setTitle] = useState("")
    const [description, setDescription] = useState("")
    const [sizes, setSizes] = useState("")

    useEffect(async () => {
        if (item.title) {
            setTitle(item.title);
            setDescription(item.description);
            setSizes(item.sizes);
        }
    }, [item]);

    const onFileChange = (event) => {
        var file = fileInputRef.current.files[0];
        var reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onloadend = function (e) {
            setImgSrc(reader.result);
        }.bind(this);
    }

    const handleSubmitForm = (event) => {
        event.preventDefault();
        const formData = new FormData(event.currentTarget);
        setTitleError("");
        setDescriptionError("");
        setImageError("");
        setSizesError("");
        if (formData.get('title').length <= 0) {
            setTitleError("Insira o titulo do item.");
            return;
        }
        if (formData.get('description').length <= 0) {
            setDescriptionError("Insira a descrição do item.");
            return;
        }
        if (formData.get('sizes').length <= 0) {
            setDescriptionError("Insira as tamanhos do item.");
            return;
        }
        if (!editing && formData.get('image').name.length <= 0) {
            setImageError("Insira uma imagem para o item.");
            return;
        }
        handleSubmit(formData);
    };

    return (
        <Box component="form" onSubmit={handleSubmitForm} sx={{ mt: 1 }}>
            <TextField
                margin="normal"
                fullWidth
                id="title"
                label="Titulo"
                value={title}
                onChange={(e)=> setTitle(e.target.value)}
                name="title"
                autoFocus
                error={titleError != ""}
                helperText={titleError === "" ? '' : titleError}
            />
            <br />
            <br />
            <TextField
                label="Tamanhos"
                fullWidth
                name="sizes"
                value={sizes}
                onChange={(e)=>setSizes(e.target.value)}
                error={sizesError != ""}
                helperText={sizesError === "" ? '' : sizesError}
            />
            <br />
            <br />
            <TextField
                label="Descrição"
                fullWidth
                name="description"
                value={description}
                onChange={(e)=>setDescription(e.target.value)}
                multiline
                rows={2}
                maxRows={2}
                error={descriptionError != ""}
                helperText={descriptionError === "" ? '' : descriptionError}
            />
            <br />
            <br />
            <Box sx={{ display: 'flex', alignItems: "center"}}>
                <label htmlFor="contained-button-file">
                <Input accept="image/*" id="contained-button-file" multiple type="file" ref={fileInputRef} name="image" onChange={onFileChange} />
                <Button variant="contained" color="secondary" component="span">
                    Adicionar imagem
                </Button>
                {imageError === "" ? null : <FormHelperText error={true}>{imageError}</FormHelperText>}
                </label>
                {imgSrc || item.image ? <img src={imgSrc ? imgSrc : item.image } width={70} height={70} style={{marginLeft: "10px", border: "1px solid black", borderRadius: "6px"}}/> : null }
            </Box>
            <Button
                type="submit"
                fullWidth
                variant="contained"
                sx={{ mt: 3, mb: 2 }}>
                {editing ? "Editar" : "Adicionar"}
            </Button>
        </Box>
    );
}
