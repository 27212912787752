import React, { useEffect, useState, useRef } from "react";
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';
import Configuration from '../../Config';
import axios from 'axios';
import { DataGrid } from '@mui/x-data-grid';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

export default function Stores() {
    const [loading, setLoading] = useState(true);
    const [users, setUsers] = useState([]);
    const [total, setTotal] = useState(0);
    const [openPointsModal, setOpenPointsModal] = useState(false);
    const [openPointsConfirmationModal, setOpenPointsConfirmationModal] = useState(false);
    const [selectEveryUser, setSelectEveryUser] = useState(false);
    const [showSelectAllUsersMessage, setShowSelectAllUsersMessage] = useState(false);
    const [pageSize, setPageSize] = useState(10);
    const [pageNumber, setPageNumber] = useState(0);
    const [selectedUserId, setSelectedUserId] = useState(0);
    const [filterField, setFilterField] = useState("all");
    const [pointsOrCouponsQuantity, setPointsOrCouponsQuantity] = useState(1);
    const [pointOrCouponField, setPointOrCouponField] = useState("points");
    const [sortModel, setSortModel] = useState([]);
    const [selectionModel, setSelectionModel] = useState([]);
    const [filterValueInputField, setFilterValueInputField] = useState("");
    const prevSelectionModel = useRef(selectionModel);

    useEffect(async () => {
        setLoading(true);
        fetchUsers();
    }, [pageSize, pageNumber, sortModel]);

    const getFilteringUrl = () => {
        let url = "page=" + pageNumber + "&size=" + pageSize;
        if (sortModel.length > 0) {
            url = url + "&orderby=" + sortModel[0].field + "&order="+sortModel[0].sort;
        }
        return url + "&filter=" + filterField + "&value=" + filterValueInputField;
    }

    const exportExcel = () => {
        let token = localStorage.getItem("token");
        let url = Configuration.BASE_URL + "/users-excel?" + getFilteringUrl() + (selectEveryUser ? "&allusers=true" : "");
        axios.post(url, { users: selectionModel }, { responseType: 'blob', headers: { Authorization: 'Bearer ' + token }
        }).then((response) => {
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', 'clientes.xlsx'); 
            document.body.appendChild(link);
            link.click();
        }).catch(function (error) {
            console.log(error);
        });
    }

    const fetchUsers = () => {
        let token = localStorage.getItem("token");
        let url = Configuration.BASE_URL + "/users?" + getFilteringUrl();
        axios.get(url, { headers: { Authorization: 'Bearer ' + token }
        }).then(function (response) {
            setTotal(response.data.total);
            setUsers([...response.data.data]);
            setLoading(false);
            setTimeout(() => {
                setSelectionModel(prevSelectionModel.current);
            });
        }).catch(function (error) {
            console.log(error);
        });
    }

    const onClickFilter = () => {
        fetchUsers();
    };

    const onSortModelChange = (newModel) => {
        setSortModel(newModel);
    };
    
    const onFilterFieldChange = (val) => {
        setFilterField(val.target.value);
    };
  
    const onPageChange = (page) => {
        prevSelectionModel.current = selectionModel;
        setPageNumber(page);
    }

    const onPageSizeChange = (page_size) => {
        setPageSize(page_size);
    }

    const onSelectionModelChange = (selectedModel) => {
        if(selectedModel.length - prevSelectionModel.current.length == pageSize) {
            setShowSelectAllUsersMessage(true);
        } else {
            setShowSelectAllUsersMessage(false);
            setSelectEveryUser(false);
            setShowSelectAllUsersMessage(false);
        }
        setSelectionModel(selectedModel);
    }

    const onSelectEveryUserClick = () => {
        setSelectEveryUser(true);
    }

    const onRemoveSelectionClick = () => {
        setSelectEveryUser(false);
        setShowSelectAllUsersMessage(false);
        setSelectionModel([]);
    }
    
    const handleAssignPointsClick = () => {
        setOpenPointsModal(false);
        setOpenPointsConfirmationModal(true);
    }

    const handleOpenAssignPointsModalOpen = (id) => {
        setSelectedUserId(id);
        setPointOrCouponField("points");
        setPointsOrCouponsQuantity(1);
        setOpenPointsModal(true);
    }

    const submitSetPointsConfirmation = () => {
        let label = (pointOrCouponField == "points"? "Pontos":"Cupões");
        let token = localStorage.getItem("token");
        let url = Configuration.BASE_URL + "/add-points-or-coupons";
        axios.post(url, {"user_id": selectedUserId, "type": pointOrCouponField, "quantity": pointsOrCouponsQuantity}, { headers: { Authorization: 'Bearer ' + token }
        }).then(function (response) {
            alert(label + " adicionados com sucesso.");
            let user = users.find(x => x.id === selectedUserId);
            if(pointOrCouponField == "points") {
                let total = 1*user.points + 1*pointsOrCouponsQuantity;
                if (total > 20) {
                    user.points = total % 20;
                    user.coupons += parseInt(total / 20);
                } else {
                    user.points = 1*user.points + 1*pointsOrCouponsQuantity;
                }
            } else {
                user.coupons = 1*user.coupons + 1*pointsOrCouponsQuantity;
            }
            setOpenPointsConfirmationModal(false);
        }).catch(function (error) {
            alert("Ocorreu um erro a adicionar os " + label);
        });
    }

    const columns = [
        {
            field: 'name', headerName: 'Nome',
            sortable: true, width: 160,
            valueGetter: (params) =>
            `${params.getValue(params.id, 'firstname') || ''} ${
                params.getValue(params.id, 'lastname') || ''
            }`,
        },
        { field: 'email', headerName: 'Email', width: 260 },
        { field: 'contact', headerName: 'Contacto', width: 70 },
        { field: 'city', headerName: 'Cidade', width: 130 },
        { field: 'contact', headerName: 'Contacto', width: 130 },
        { field: 'verified', headerName: 'Verificado', width: 110, valueGetter: (params) => params.getValue(params.id, 'isVerified') ? "Sim" : "Não" },
        { field: 'points', headerName: 'Pontos atuais' },
        { field: 'used_points', headerName: 'Pontos utilizados' },
        { field: 'coupons', headerName: 'Cupões atuais' },
        { field: 'used_coupons', headerName: 'Cupões utilizados' },
        {
            width: 180,
            alignItems: "center",
            field: 'actions',
            headerName: 'Ações',
            renderCell: (params) => (
                <Button
                    variant="contained"
                    color="primary"
                    size="small"
                    onClick={() => handleOpenAssignPointsModalOpen(params.id)}
                >
                    Atribuir pontos
                </Button>
            ),
        },
    ];

    return (
        <Grid item xs={12} md={12} lg={12}>
            <Paper sx={{ p: 2 }}>
                <Grid mt={0} alignItems="center" justifyContent="center">
                    {loading ? <Box sx={{ display: 'flex', alignItems: "center", justifyContent: "center" }}><CircularProgress /></Box>:
                    <div style={{ width: '100%' }}>
                        <Grid container spacing={2}>
                            <Grid item xs={4}>
                                <FormControl fullWidth>
                                    <InputLabel id="filterField">Filtro</InputLabel>
                                    <Select
                                        id="filterField"
                                        value={filterField}
                                        label="Age"
                                        onChange={onFilterFieldChange}
                                    >
                                        <MenuItem value={"all"}>Todos os campos</MenuItem>
                                        <MenuItem value={"name"}>Nome</MenuItem>
                                        <MenuItem value={"email"}>Email</MenuItem>
                                        <MenuItem value={"contact"}>Contacto</MenuItem>
                                        <MenuItem value={"city"}>Cidade</MenuItem>
                                    </Select>
                                </FormControl>
                            </Grid>
                            <Grid item xs={3}>
                                <TextField 
                                    value={filterValueInputField}
                                    onChange={(val) => setFilterValueInputField(val.target.value)}
                                    label="Valor filtro" variant="outlined" />
                                
                            </Grid>
                            <Grid item xs={2}>
                                <Button onClick={onClickFilter} variant="contained">Filtrar</Button>
                            </Grid>
                            <Grid item xs={3}>
                                <Button disabled={!selectEveryUser && selectionModel.length <= 0 } onClick={exportExcel} variant="contained">Exportar Excel</Button>
                            </Grid>
                        </Grid>
                        {showSelectAllUsersMessage && !selectEveryUser ? <Grid container marginTop={2} paddingTop={1} textAlign="center" bgcolor="rgba(0,0,0,0.04)">
                            <Grid item xs={12}>
                                Todas os {pageSize} utilizadores desta página estão selecionados. <Button onClick={onSelectEveryUserClick}>Selecionar todos os {total} utilizadores da pesquisa.</Button>
                            </Grid>
                        </Grid> : null }
                        {selectEveryUser ? <Grid container marginTop={2} paddingTop={1} textAlign="center" bgcolor="rgba(0,0,0,0.04)">
                            <Grid item xs={12}>
                                Todas os {total} utilizadores estão selecionados. <Button onClick={onRemoveSelectionClick}>Limpar seleção</Button>
                            </Grid>
                        </Grid> : null }
                        <br></br>
                        <DataGrid 
                            sortingMode="server"
                            paginationMode="server"
                            sortModel={sortModel}
                            onSortModelChange={onSortModelChange}
                            checkboxSelection
                            disableSelectionOnClick
                            autoHeight={true}
                            rowCount={total}
                            disableColumnFilter={true}
                            disableColumnMenu={true}
                            sortable={false}
                            rows={users}
                            page={pageNumber}
                            pageSize={pageSize} 
                            columns={columns} 
                            selectionModel={selectionModel}
                            onSelectionModelChange={onSelectionModelChange}
                            onPageChange={onPageChange}
                            onPageSizeChange={onPageSizeChange}
                            rowsPerPageOptions={[10, 25, 50]} 
                            loading={loading}
                        />
                    </div>}
                </Grid>
            </Paper>
            <Dialog open={openPointsModal}>
                <DialogTitle>Atribuição de pontos e cupões</DialogTitle>
                <DialogContent>
                    <Grid container spacing={2}>
                        <Grid item xs={6} mt={1}>
                            <FormControl fullWidth>
                                <InputLabel id="pointsField">Tipo</InputLabel>
                                    <Select
                                        id="pointsField"
                                        fullWidth={true}
                                        value={pointOrCouponField}
                                        onChange={(val) => setPointOrCouponField(val.target.value)}
                                    >
                                    <MenuItem value={"points"}>Pontos</MenuItem>
                                    <MenuItem value={"coupons"}>Cupões</MenuItem>
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item xs={6}>
                            <TextField
                                value={pointsOrCouponsQuantity}
                                onChange={(val) => setPointsOrCouponsQuantity(val.target.value)}
                                InputProps={{ inputProps: { min: 1 } }}
                                autoFocus
                                margin="dense"
                                label={pointOrCouponField == "points"? "Pontos" : "Cupões"}
                                type="number"
                                fullWidth
                                variant="outlined"
                            />
                        </Grid>
                    </Grid>
                    
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setOpenPointsModal(false)}>Cancelar</Button>
                    <Button onClick={handleAssignPointsClick}>Atribuir</Button>
                </DialogActions>
            </Dialog>
            <Dialog open={openPointsConfirmationModal}>
                <DialogTitle>Confirmação</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Tem a certeza que pertende adicionar {pointsOrCouponsQuantity} {pointOrCouponField == "points"? "Pontos":"Cupões"} ao utilizador?
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setOpenPointsConfirmationModal(false)}>Cancelar</Button>
                    <Button onClick={() => submitSetPointsConfirmation()}>Sim</Button>
                </DialogActions>
            </Dialog>
        </Grid>
    );
}
