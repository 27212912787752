import React, { useState, useEffect } from 'react';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';
import styled from 'styled-components';
import axios from 'axios';
import Configuration from '../../Config';
import { useParams, useNavigate } from "react-router-dom";
import StoreForm from './StoreForm';
import IconButton from '@mui/material/IconButton';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

const Input = styled('input')({
  display: 'none',
});

export default function EditStore() {
    const navigate = useNavigate();
    const { id } = useParams();
    const [store, setStore] = useState({});
    useEffect(async () => {
        fetchStore(id);
    }, []);
      
    const fetchStore = (id) => {
        let token = localStorage.getItem("token");
        axios.get(Configuration.BASE_URL + "/store/" + id, { headers: { Authorization: 'Bearer ' + token }
        }).then(function (response) {
          setStore(response.data);
        }).catch(function (error) {
          console.log(error);
        });
    } 

    const handleSubmit = (data) => {
        let token = localStorage.getItem("token");
        axios.post(Configuration.BASE_URL + "/store/edit/" +  id , data, { headers: { Authorization: 'Bearer ' + token } })
        .then((response) => {
            navigate("/stores/"+store.location);
        }).catch((error) => {
        });
    };

    return (
        <Grid item xs={12} md={12} lg={12}>
        <Paper
        sx={{
            p: 2,
            display: 'flex',
            flexDirection: 'column',
        }}
        > 
        <Grid item md={12}>
            <IconButton onClick={() => navigate('/stores/' + store.location) } aria-label="qrcode">
                <ArrowBackIcon />
            </IconButton>
            <Typography variant="h5" color="text.secondary" align="center">
                Editar Restaurante
            </Typography>
            {store ? <StoreForm handleSubmit={handleSubmit} editing={true} store={store} />
            : <Box sx={{ display: 'flex', alignItems: "center", justifyContent: "center" }}><CircularProgress /></Box>
            }
        </Grid>
        </Paper>
    </Grid>
    );
}
