import { render } from "react-dom";
import {
  BrowserRouter,
  Routes,
  Route,
  Navigate
} from "react-router-dom";
import Dashboard from "./pages/dashboard/Dashboard";
import Users from "./pages/users/Users";
import Menu from "./pages/menu/Menu";
import AddMenuItem from "./pages/menu/Add";
import EditItemMenu from "./pages/menu/Edit";
import SignIn from './pages/authentication/login';
import Stores from './pages/stores/Stores'
import AddStore from "./pages/stores/Add";
import EditStore from "./pages/stores/Edit";
import Texts from "./pages/texts/Texts";
import EditText from "./pages/texts/Edit";
import ShowStoreQRCode from "./pages/stores/Show";

const rootElement = document.getElementById("root");
render(
  <BrowserRouter>
    <Routes>
      <Route path="login" element={<SignIn />} />
      <Route path="/" element={<Dashboard />}>
        <Route path="/" element={<Navigate to='/menu/pt'/>} />
        <Route path="menu" element={<Navigate to='/menu/pt'/>} />
        <Route path="menu/:language" element={<Menu />} />
        <Route path="menu/:language/add" element={<AddMenuItem />} />
        <Route path="menu/edit/:id" element={<EditItemMenu />} />

        <Route path="stores" element={<Navigate to='/stores/pt'/>} />
        <Route path="stores/:language" element={<Stores />} />
        <Route path="stores/:language/add" element={<AddStore />} />
        <Route path="stores/edit/:id" element={<EditStore />} />
        <Route path="stores/qrcode/:id" element={<ShowStoreQRCode />} />
        
        <Route path="texts" element={<Navigate to='/texts/pt'/>} />
        <Route path="texts/:language" element={<Texts />} />
        <Route path="texts/edit/:id" element={<EditText />} />

        <Route path="users" element={<Users />} />
      </Route>
    </Routes>
  </BrowserRouter>,
  rootElement
);