import React, { useEffect, useState } from "react";
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import AddBoxIcon from '@mui/icons-material/AddBox';
import Button from '@mui/material/Button';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Avatar from '@mui/material/Avatar';
import { Link } from "react-router-dom";
import Configuration from '../../Config';
import axios from 'axios';
import Box from '@mui/material/Box';
import { useParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import CircularProgress from '@mui/material/CircularProgress';
import IconButton from '@mui/material/IconButton';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import QrCodeIcon from '@mui/icons-material/QrCode';

export default function Stores() {
  const [loading, setLoading] = useState(true);
  const [stores, setStores] = useState([]);
  const [country, setCountry] = useState(0);
  const { language } = useParams();
  const navigate = useNavigate();

  useEffect(async () => {
    setLoading(true);
    setCountry(language == "es" ? 1 : 0);
    fetchStores(language);
  }, []);
  
  const fetchStores = (language = "pt") => {
    let token = localStorage.getItem("token");
    axios.post(Configuration.BASE_URL + "/stores", { "country" : language}, { headers: { Authorization: 'Bearer ' + token }
    }).then(function (response) {
      setLoading(false);
      setStores([...response.data]);
    }).catch(function (error) {
      console.log(error);
    });
  } 

  const handleChange = (event, newValue) => {
    let language = "pt";
    if (newValue == 1) {
      language = "es";
    }
    setCountry(newValue);
    navigate(newValue == 1 ? "/stores/es" : "/stores/pt");
    fetchStores(language);
  }

  const confirmDeletion = (item) => {
    if(window.confirm('De certeza que pertende eliminar a loja: "'+ item.title +'"?')) {
      let token = localStorage.getItem("token");
      axios.delete(Configuration.BASE_URL + "/store/"+item.id+"/delete", { headers: { Authorization: 'Bearer ' + token }
      }).then(function (response) {
        window.location.reload();
      }).catch(function (error) {
        console.log(error);
      });
    }
  }

  return (
    <Grid item xs={12} md={12} lg={12}>
    <Paper sx={{ p: 2, minHeight: '200px', }}>
      <Grid>
        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
          <Tabs value={country} onChange={handleChange} aria-label="Countries">
            <Tab icon={<Avatar alt="Portugal" src="/assets/portugal.png" style={{height: 30, width: 30, alignSelf: 'center', marginLeft: 5}} />} />
            <Tab icon={<Avatar alt="Portugal" src="/assets/espanha.png" style={{height: 30, width: 30, alignSelf: 'center', marginLeft: 5}} />}/>
          </Tabs>
        </Box>
        <Button sx={{float: 'right', marginTop: 2}} variant="contained" endIcon={<AddBoxIcon />} component={Link} to="add">
          Adicionar
        </Button>
      </Grid>
      <Grid mt={12} alignItems="center" justifyContent="center">
        {loading ? <Box sx={{ display: 'flex', alignItems: "center", justifyContent: "center" }}><CircularProgress /></Box> : null}
        {!loading && stores.length <= 0 ? <h4 align={"center"}>Sem lojas adicionadas</h4> : ""}
        {!loading && stores.map((item) => 
          <Paper key={item.id} elevation={2} style={{marginBottom: '10px', padding: 8, minHeight: 60, cursor: "pointer" }}>
            <Grid container>
              <Grid item ml={2} md={9}>
                <h3 style={{whiteSpace : "pre-wrap", color: "#ab0303", margin: "0px"}}><strong>{item.title}</strong></h3>
                <div>{item.address}</div>
              </Grid>
              <Grid item md={2} ml={2} sx={{ display: 'flex', alignItems: "center", justifyContent: "center" }}>
                  <IconButton onClick={() => navigate('/stores/qrcode/' + item.id) } aria-label="qrcode">
                    <QrCodeIcon />
                  </IconButton>
                  <IconButton onClick={() => navigate('/stores/edit/' + item.id) } aria-label="edit">
                    <EditIcon />
                  </IconButton>
                  <IconButton onClick={() => confirmDeletion(item)} aria-label="delete">
                    <DeleteIcon />
                  </IconButton>
              </Grid>
            </Grid>
          </Paper>
        )}
      </Grid>
    </Paper>
  </Grid>
  );
}
