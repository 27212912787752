import React, { useEffect, useState } from "react";
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Avatar from '@mui/material/Avatar';
import Configuration from '../../Config';
import axios from 'axios';
import Box from '@mui/material/Box';
import { useParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import CircularProgress from '@mui/material/CircularProgress';
import TextItem from "./TextItem";

export default function Texts() {
  const [loading, setLoading] = useState(true);
  const [texts, setTexts] = useState([]);
  const [country, setCountry] = useState(0);
  const { language } = useParams();
  const navigate = useNavigate();

  useEffect(async () => {
    setLoading(true);
    setCountry(language == "es" ? 1 : 0);
    fetchTexts(language);
  }, []);
  
  const fetchTexts = (language = "pt") => {
    let token = localStorage.getItem("token");
    axios.post(Configuration.BASE_URL + "/texts", { "country" : language}, { headers: { Authorization: 'Bearer ' + token }
    }).then(function (response) {
      setLoading(false);
      setTexts([...response.data]);
    }).catch(function (error) {
      console.log(error);
    });
  } 

  const handleChange = (event, newValue) => {
    let language = "pt";
    if (newValue == 1) {
      language = "es";
    }
    setCountry(newValue);
    navigate(newValue == 1 ? "/texts/es" : "/texts/pt");
    fetchTexts(language);
  }
//onClick={() => navigate('/menu/edit/' + item.id)}
  return (
    <Grid item xs={12} md={12} lg={12}>
    <Paper sx={{ p: 2, minHeight: '200px', }}>
      <Grid>
        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
          <Tabs value={country} onChange={handleChange} aria-label="Countries">
            <Tab icon={<Avatar alt="Portugal" src="/assets/portugal.png" style={{height: 30, width: 30, alignSelf: 'center', marginLeft: 5}} />} />
            <Tab icon={<Avatar alt="Portugal" src="/assets/espanha.png" style={{height: 30, width: 30, alignSelf: 'center', marginLeft: 5}} />}/>
          </Tabs>
        </Box>
      </Grid>
      <Grid mt={5} alignItems="center" justifyContent="center">
        {loading ? <Box sx={{ display: 'flex', alignItems: "center", justifyContent: "center" }}><CircularProgress /></Box> : null}
        {!loading && texts.length <= 0 ? <h4 align={"center"}>Sem textos</h4> : ""}
        <Grid container>
        {!loading && texts.map((item) => 
            <Grid key={item.id} item ml={4} md={6} paddingX="5px" margin="0" width="100%" onClick={() => navigate("/texts/edit/" + item.id)}>
                <TextItem item={item} />
            </Grid>
        )}
        </Grid>
      </Grid>
    </Paper>
  </Grid>
  );
}
