import React, { useState, useRef } from 'react';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import styled from 'styled-components';
import axios from 'axios';
import Configuration from '../../Config';
import { useParams, useNavigate } from "react-router-dom";
import MenuForm from './MenuForm';
import IconButton from '@mui/material/IconButton';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

const Input = styled('input')({
  display: 'none',
});

export default function AdicionarItemMenu() {
  const navigate = useNavigate();
  const { language } = useParams();

  const handleSubmit = (formData) => {
      formData.append("language", language);
      let token = localStorage.getItem("token");
      axios.post(Configuration.BASE_URL + "/menu", formData, { headers: { Authorization: 'Bearer ' + token, "Content-Type": "multipart/form-data" } })
      .then((response) => {
          navigate("/menu/"+language);
      }).catch((error) => {
      });
  };

  return (
    <Grid item xs={12} md={12} lg={12}>
    <Paper
      sx={{
        p: 2,
        display: 'flex',
        flexDirection: 'column',
      }}
    > 
      <Grid item md={12}>
        <IconButton onClick={() => navigate('/menu/' + language) } aria-label="qrcode">
          <ArrowBackIcon />
        </IconButton>
        <Typography variant="h5" color="text.secondary" align="center">
          Adicionar Item
        </Typography>
        <MenuForm handleSubmit={handleSubmit} edit={false} />
      </Grid>
    </Paper>
  </Grid>
  );
}
