import React, { useState, useRef, useEffect } from 'react';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import InputAdornment from '@mui/material/InputAdornment';
import Grid from '@mui/material/Grid';
import styled from 'styled-components';
import FormHelperText from '@mui/material/FormHelperText';

const Input = styled('input')({
  display: 'none',
});

export default function StoreForm({ editing = false, handleSubmit, store={}, language}) {
    const [titleError, setTitleError] = useState("");
    const [addressError, setAddressError] = useState("");
    const [mapsUrlError, setMapsUrlError] = useState("");
    const [schedulesError, setSchedulesError] = useState("");
    const [contactsError, setContactsError] = useState("");
    // const [latitudeError, setLatitudeError] = useState("");
    // const [longitudeError, setLongitudeError] = useState("");
    const [phoneError, setPhoneError] = useState("");
    
    const [title, setTitle] = useState("")
    const [address, setAddress] = useState("");
    const [mapsUrl, setMapsUrl] = useState("");
    const [schedules, setSchedules] = useState("");
    const [contacts, setContacts] = useState("");
    // const [latitude, setLatitude] = useState("");
    // const [longitude, setLongitude] = useState("");
    const [phone, setPhone] = useState("");

    useEffect(async () => {
        if (store.title) {
            setTitle(store.title);
            setAddress(store.address);
            setMapsUrl(store.maps_url);
            setSchedules(store.schedules);
            setContacts(store.contacts);
            // setLatitude(store.latitude);
            // setLongitude(store.longitude);
            setPhone(store.mainContact);
        }
    }, [store]);

    const handleSubmitForm = (event) => {
        event.preventDefault();
        setTitleError("");
        setAddressError("");
        setMapsUrlError("");
        setSchedulesError("");
        setContactsError("");
        // setLatitudeError("");
        // setLongitudeError("");
        setPhoneError("");
        if (title.length <= 0) {
            setTitleError("Insira o titulo da loja.");
            return;
        }
        if (address.length <= 0) {
            setAddressError("Insira a morada da loja.");
            return;
        }
        // if (latitude.length <= 0) {
        //     setLatitudeError("Insira a latitude da loja.");
        //     return;
        // } else if(!/^(\+|-)?(?:90(?:(?:\.0{1,6})?)|(?:[0-9]|[1-8][0-9])(?:(?:\.[0-9]{1,6})?))/.test(latitude)) {
        //     setLatitudeError("Insira uma latitude válida.");
        //     return;
        // }

        // if (longitude.length <= 0) {
        //     setLongitudeError("Insira a longitude da loja.");
        //     return;
        // } else if(!/^(\+|-)?(?:180(?:(?:\.0{1,6})?)|(?:[0-9]|[1-9][0-9]|1[0-7][0-9])(?:(?:\.[0-9]{1,6})?))/.test(longitude)) {
        //     setLongitudeError("Insira uma longitude válida.");
        //     return;
        // }
        if (mapsUrl.length <= 0) {
            setMapsUrlError("Insira o link para o google maps da loja.");
            return;
        } else if (!mapsUrl.startsWith("https://www.google.com/maps")) {
            setMapsUrlError("Certifique-se de que está a inserir um link válido do google maps. (Começado por https://www.google.com/maps)");
            return;
        }
        if (schedules.length <= 0) {
            setSchedulesError("Insira o(s) horário(s) da loja.");
            return;
        }
        if (contacts.length <= 0) {
            setContactsError("Insira os contactos da loja.");
            return;
        }
        if (phone.length <= 0) {
            setPhoneError("Insira o telefone/telemóvel principal da loja.");
            return;
        } else if(language == "pt" && !/^(2([1-8]\d|9[136])|30\d|7(0[789]|60)|80[08]|9([136]\d|[124-7]))(\d{6})/.test(phone)) {
            setPhoneError("Insira um contacto válido para Portugal.");
            return;
        }
        handleSubmit({
            title,
            address,
            mapsUrl,
            schedules,
            contacts,
            phone,
            language
        });
    };

    return (
        <Box component="form" onSubmit={handleSubmitForm} sx={{ mt: 1 }}>
            <TextField
                margin="normal"
                fullWidth
                id="title"
                label="Titulo"
                value={title}
                onChange={(e)=> setTitle(e.target.value)}
                name="title"
                autoFocus
                error={titleError != ""}
                helperText={titleError === "" ? '' : titleError}
            />
            <br />
            <br />
            <TextField
                label="Morada"
                fullWidth
                name="address"
                value={address}
                onChange={(e)=>setAddress(e.target.value)}
                error={addressError != ""}
                helperText={addressError === "" ? '' : addressError}
            />
            <br />
            <br />
            <TextField
                label="Url da loja no google maps"
                fullWidth
                name="mapsUrl"
                value={mapsUrl}
                onChange={(e)=>setMapsUrl(e.target.value)}
                error={mapsUrlError != ""}
                helperText={mapsUrlError === "" ? '' : mapsUrlError}
            />
            <br />
            <br />
            {/* <Grid>
                <TextField
                    label="Latitude"
                    name="latitude"
                    value={latitude}
                    onChange={(e)=>setLatitude(e.target.value)}
                    error={latitudeError != ""}
                    helperText={latitudeError === "" ? '' : latitudeError}
                />
                <TextField
                    style={{marginLeft: 20}}
                    label="Longitude"
                    name="longitude"
                    value={longitude}
                    onChange={(e)=>setLongitude(e.target.value)}
                    error={longitudeError != ""}
                    helperText={longitudeError === "" ? '' : longitudeError}
                />
            </Grid>
            <br /> */}
            <TextField
                label="Horário(s)"
                fullWidth
                name="schedules"
                value={schedules}
                onChange={(e)=>setSchedules(e.target.value)}
                error={schedulesError != ""}
                helperText={schedulesError === "" ? '' : schedulesError}
            />
            <br />
            <br />
            <TextField
                label="Contacto(s)"
                fullWidth
                name="contacts"
                value={contacts}
                onChange={(e)=>setContacts(e.target.value)}
                error={contactsError != ""}
                helperText={contactsError === "" ? '' : contactsError}
            />
            <br />
            <br />
            <TextField
                InputProps={{
                    startAdornment: <InputAdornment position="start">{(store.location ? store.location == "pt" : language == "pt") ? "+351" : "+34"}</InputAdornment>,
                }}
                label="Contacto Principal"
                name="phone"
                value={phone}
                onChange={(e)=>setPhone(e.target.value)}
                error={phoneError != ""}
                helperText={phoneError === "" ? '' : phoneError}
            />
            <br />
            <br />
            <Button
                type="submit"
                fullWidth
                variant="contained"
                sx={{ mt: 3, mb: 2 }}>
                {editing ? "Editar" : "Adicionar"}
            </Button>
        </Box>
    );
}
