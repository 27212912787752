import React, { useState, useEffect } from 'react';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';
import Button from '@mui/material/Button';
import Alert from '@mui/material/Alert';
import styled from 'styled-components';
import axios from 'axios';
import Configuration from '../../Config';
import { useParams, useNavigate } from "react-router-dom";
import QRCode from "react-qr-code";
import IconButton from '@mui/material/IconButton';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

const Input = styled('input')({
  display: 'none',
});

export default function ShowStoreQRCode() {
    const navigate = useNavigate();
    const { id } = useParams();
    const [store, setStore] = useState({});
    const [success, setSuccess] = useState(false);
    const [error, setError] = useState(false);
    useEffect(async () => {
        fetchStore(id);
    }, []);
      
    const fetchStore = (id) => {
        let token = localStorage.getItem("token");
        axios.get(Configuration.BASE_URL + "/store/" + id, { headers: { Authorization: 'Bearer ' + token }
        }).then(function (response) {
          setStore(response.data);
        }).catch(function (error) {
          console.log(error);
        });
    } 

    const refreshQrcode = () => {
        let token = localStorage.getItem("token");
        setSuccess(false);
        setError(false);
        axios.post(Configuration.BASE_URL + "/store/qrcode/" + id, {}, { headers: { Authorization: 'Bearer ' + token }})
        .then((response) => {
            setSuccess(true);
            fetchStore(id);
        }).catch((error) => {
            setError(true);
        });
    };

    return (
        <Grid item xs={12} md={12} lg={12}>
        <Paper
        sx={{
            p: 2,
            display: 'flex',
            flexDirection: 'column',
        }}
        > 
        <Grid item md={12}>
            {!store ? <Box sx={{ display: 'flex', alignItems: "center", justifyContent: "center" }}><CircularProgress /></Box>
            :
            <Grid>
                <IconButton onClick={() => navigate('/stores/' + store.location) } aria-label="qrcode">
                    <ArrowBackIcon />
                  </IconButton>
                <Typography mb={5} variant="h5" color="text.secondary" align="center">
                    Editar QRcode {store.title}
                </Typography>
                { success ? <Alert severity="success">QrCode alterado com sucesso, terá de imprimir este novo QrCode para o restaurante {store.title} continuar a poder fornecer ofertas.</Alert> : null}
                { error ? <Alert severity="error">Não foi possivel alterar o QRCode da loja {store.title}.</Alert> : null}
                <Box mt={5} sx={{ display: 'flex', alignItems: "center", justifyContent: "center" }}>
                    <QRCode value={"{\"id\":\""+store.id+"\", \"token\":\""+store.qrcode+"\"}"} />
                </Box>
                <Box sx={{ display: 'flex', alignItems: "center", justifyContent: "center" }}>
                    <Button
                        onClick={() => refreshQrcode()}
                        type="refresh"
                        variant="contained"
                        sx={{ mt: 3, mb: 2 }}>
                        Renovar/Alterar QrCode
                    </Button>
                </Box>
            </Grid>
            }
        </Grid>
        </Paper>
    </Grid>
    );
}
