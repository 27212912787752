import React from "react";
import Paper from '@mui/material/Paper';
import { useNavigate } from "react-router-dom";
import FormatAlignLeftIcon from '@mui/icons-material/FormatAlignLeft';

export default function TextItem({ item }) {

    const navigate = useNavigate();

    return (
        <Paper key={item.id} elevation={2} className="text-item" style={{
            textAlign: "center", 
            marginBottom: '10px', 
            padding: 8, 
            minHeight: "30vh",
            cursor: "pointer",
        }} sx={{ "&:hover": {  backgroundColor: "#DDD !important", color: "white !important" }}}>
            <FormatAlignLeftIcon style={{minHeight: "17vh", width: "auto", color: "#333", marginTop: "10px" }}/>
            <h3 style={{whiteSpace : "pre-wrap", color: "#333"}}><strong>{item.title}</strong></h3>
        </Paper>
    );
}
